// Core variables and mixins
@import "res/scss/__variables.scss";
@import "res/scss/__mixins.scss";

$m-height: 65px;
$pc-height: 84px;

/*=====MOBILE=====*/
body.m-nav-open{
  // overflow-y: hidden;
  position: fixed;
  width: 100%;
}
.mobile-header{
  position: fixed;
  top:0;
  width: 100%;
  height: $m-height;
  line-height: $m-height;
  z-index: 700;
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
  .logo{
    margin-left: 20px;
    text-align: left;
  }
}
.mobile-header .logo-img {
  position: relative;
  display: inline-block;
  width: 160px;
  height: 35px;
  background-image: url(/views/res/imgs/common/main_logo_dark.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  vertical-align: middle;
  // z-index: 750;
}
/* 네비게이션 메뉴 버튼 */
.mobile-nav-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 800;
}
.mobile-nav-menu .menu-btn-wrap{
  width: 100%;
  background-color: #000;
}
.mobile-header.on .menu-btn-wrap{
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.mobile-nav-menu .menuBtn{
  position: absolute;
  right: 15px;
  top: 19px;
  background-color: transparent;
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 1;
  font-size: 0;
}
.mobile-nav-menu .menuBtn span{
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: #000000;
  margin: 0.5px;
  border-radius: 2px;
  vertical-align: middle;
  @include prefixer("transition", opacity .4s .55s, webkit moz ms);
}
.mobile-nav-menu .menuBtn.on span{
  @include prefixer("transition", opacity .4s, webkit moz ms);
}
.mobile-nav-menu .menuBtn.on .five{
  opacity: 0;
}
.mobile-nav-menu .menuBtn .last {
  opacity: 0;
}
.mobile-nav-menu .menuBtn.on .last{
  opacity: 1;
}

.mobile-nav{
  height: calc(100% - 130px);
  overflow-y: auto;
}
/* 네이게이션 컨텐츠 */
.mobile-nav-wrap{
  position: fixed;
  top:0;
  width: 100%;
  background-color: #fff;
  height: 100%;
  z-index: 700;
  overflow: auto;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  transition: transform 1s;
  .logo{
    background-color: transparent;
  }
}
.mobile-header.on .mobile-nav-wrap{
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.mobile-nav-wrap .nav-top-menu{
  padding: 26px 15px;
}
.mobile-nav-wrap .mobile-nav .aco{
  font-size: 20px;
  color: #000;
}
.mobile-nav-wrap .mobile-nav .aco a{
  height: 63px;
  line-height: 63px;
  padding-left: 20px;
}
.mobile-nav-wrap .mobile-nav .aco i{
  right: 20px;
  font-size: 20px;
}
.mobile-nav-wrap .mobile-nav .aco.on {
  & > a {
    color:$g_point-theme-color;
  }
  i {
    transform: translate(0%, -50%) rotate(-180deg);
  }
}
.mobile-nav-wrap .submenu{
  display:none;
}
.mobile-nav-wrap .aco.on .submenu {
  display: block;
  font-weight: 600;
}
.mobile-nav-wrap .submenu li{
  background-color: #f8f8f8;
  border-top: 1px solid #ccc;
  font-size: 15px;
  color: #555;
  padding-left: 20px;
  &.on {
    color:#000;
  }
  &:last-child{
    border-bottom: 1px solid #ccc;
  }
}
.mobile-login-wrap{
  position: absolute;
  bottom: 0;
  padding-left: 15px;
  width:100%;
  background-color: #fff;
  z-index: 1;
  li{
    display: inline-block;
    font-size: 16px;
    color:#000;
    &:first-child{
      margin-right: 11px;
      &:after{
        content:"";
        display: inline-block;
        width: 1px;
        height: 16px;
        margin-left: 16px;
        background-color:#dcdcdc;
        vertical-align: middle;
      }
    }
  }
}
/* 네비게이션 뒤 배경 */
.mobile-header .mobileBg{
  display: none;
}
.mobile-header .mobileBg{
  position: fixed;
  top:0;
  bottom:0;
  width: 100%;
  background-color: rgba(0,0,0,.4);
}
/*=====MOBILE end=====*/

/*=====PC=====*/
@media screen and (min-width: 992px) {
  .pc-header{
    position:fixed;
    width:100%;
    height: $pc-height;
    border-bottom:1px solid rgba(255,255,255, 0.3);
    background-color: rgba(0, 0, 0, 0.5);
    @include prefixer("transition", all .5s, webkit moz ms);
    will-change: top;
    z-index: 800;
    &:hover, &.white{
      background-color:#fff;
      border-color: #e1e1e1;
      .pc-nav .menu-list div > a {
        color:#000;
      }
      .right-menu .blog-icon{
        background-image: url(/views/res/imgs/common/icon_head_sns_blog_grey.png);
      }
      .logo .logo-img {
        background-image:url(/views/res/imgs/common/main_logo_dark.svg);
      }
    }
  }
  /* 헤더 fix */
  .pc-header.fixed{
    position: fixed;
    top:-100px;
    width: 100%;
  }
  .pc-header.fixed-slideDown{
    top:0;
  }
  .pc-header .logo{
    position:absolute;
    left:15px;
    top:50%;
    -webkit-transform:translateY(-50%);
    transform:translateY(-50%);
  }
  .pc-header .logo .logo-img{
    display: inline-block;
    vertical-align: middle;
    width:180px;
    height:40px;
    background-repeat:no-repeat;
    background-size: contain;
    background-image:url(/views/res/imgs/common/main_logo.svg);
  }

  /* 로그인/회원가입 */
  .pc-header .right-menu{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    font-size: 0;
  }
  .pc-header .right-menu {
    .right-button {
      display: inline-block;
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      font-family: $font-lato;
      color: #fff;
      background-color: $g_point-theme-color;
      vertical-align: middle;
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.63);
    }
    .blog-icon{
      display: inline-block;
      width: 30px;
      height: 30px;
      vertical-align: middle;
      background-image: url(/views/res/imgs/common/icon_head_sns_blog.png) ;
      background-size: inherit;
      background-position: center;
      background-repeat: no-repeat;
      &>a{
        width: 100%;
        height: 100%;
      }
    }
  }
  /* 네비게이션 컨텐츠 */
  .pc-nav{
    // width: 97%;
    font-size:0;
    text-align:center;
  }
  .pc-nav .menu-list{
    display:inline-block;
    padding:0 2.2%;
  }
  .pc-nav .menu-list div>a{
    display: block;
    line-height:$pc-height;
    font-size: 18px;
    color:#fff;
  }
  .pc-nav .menu-list .sub-menu-list-wrap{
    display: none;
  }
  .pc-nav .menu-list.on .sub-menu-list-wrap{
    display: block;
  }
  .pc-nav .sub-menu-list-wrap{
    position:absolute;
    left:50%;
    top:100%;
    width:100%;
    white-space: nowrap;
    z-index:999;
    transform: translateX(-50%);
    transition: all .5s;
  }
  .pc-nav .menu-list:nth-child(6) .sub-menu-list-wrap{
    left: auto;
    right: -300px;
  }
  .pc-nav .sub-menu-list-wrap .sub-menu-list{
    display:inline-block;
    height:60px;
    line-height:60px;
    margin-left:30px
  }
  .pc-nav .sub-menu-list-wrap .sub-menu-list:first-child{
    margin-left:0
  }
  .pc-nav .sub-menu-list-wrap .sub-menu-list a{
    font-size:14px;
    color:#000
  }
  .pc-nav .sub-menu-list-wrap .sub-menu-list a:hover{
    color:$g_point-theme-color;
  }
  .pc-header .navBg{
    position:absolute;
    left:0;
    top:100%;
    width:100%;
    height:60px;
    border-top:1px solid #e1e1e1;
    border-bottom:1px solid #e1e1e1;
    background-color:#fff;
    z-index:400;
    transition: all .5s;
  }
  /*=====PC end=====*/
}